
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {SocketEvent} from "@/enum";
import {Socket} from "socket.io-client";
import JoypadGameScreen from "@/components/JoypadGameScreen.vue";
import {State} from "vuex-class";

@Component({
    name: "GamePhaseJoypad",
    components: {JoypadGameScreen},
})
export default class GamePhaseJoypad extends Vue {
    // props
    @Prop() data: any;
    @Prop(Object) socket!: Socket;

    // data
    @State("playerState") playerState!: any;

    // computed

    // methods
    startButton(type: string) {
        this.socket.emit(SocketEvent.action, {action: "StartBouton" + type, message: "Start Bouton A"});
    }

    stopButton(type: string) {
        this.socket.emit(SocketEvent.action, {action: "EndBouton" + type, message: "End Bouton A"});
    }

    joystick(position: any) {
        this.socket.emit(SocketEvent.action, {action: "xymove", uxmove: position.uxmove, uymove: position.uymove});
    }

    // watch
    @Watch("playerState")
    watchPlayerState(state: any) {
        console.log("state = ", state);
    }

    // hooks
}
