
import {Component, Vue, Prop} from 'vue-property-decorator';
import Action from "@/components/Action.vue";
import {State} from "vuex-class";
import {Socket} from "socket.io-client";
import InteractiveMap from "@/components/InteractiveMap.vue";
import {Position} from "@/types";
import {SocketEvent} from "@/enum";
import QrCode from "@/components/QrCode.vue";

enum ActionOn {
    tap = "tap",
    doubletap = "doubletap"
}

@Component({
    name: "GamePhaseMapPlacement",
    components: {QrCode, InteractiveMap, Action},
})
export default class GamePhaseMapPlacement extends Vue {
    // props
    @Prop() data: any;
    @Prop(Object) socket!: Socket;

    // data
    @State("playerState") playerState!: any;

    showExplanation = true;
    showQrCode = false;

    // computed
    get actionOn(): ActionOn {
        if(this.data && (this.data.actionOn === "doubletap")) {
            return ActionOn.doubletap;
        }
        else {
            return ActionOn.tap;
        }
    }

    get sentence(): string {
        return "Map placement...";
    }

    get inventoryItems(): Array<any> {
        if(this.playerState && this.playerState.inventory && this.playerState.inventory.items) {
            return this.playerState.inventory.items;
        }
        else {
            return [];
        }
    }


    // methods
    nextPhase() {
        this.$emit("nextPhase");
    }

    actionTap(position: Position) {
        if(this.actionOn === ActionOn.tap) {
            this.action(position);
        }
    }

    actionDoubleTap(position: Position) {
        if(this.actionOn === ActionOn.doubletap) {
            this.action(position);
        }
    }

    action(position: Position) {
        console.log("action at ", position.x, ", ", position.y);
        this.socket.emit(SocketEvent.action, {action: "ground_tap", x: position.x, y: position.y});
        console.log("playerState: ", this.playerState);
    }

    inventoryItemIsMultiple(item: any): boolean {
        if(this.data && this.data.inventory) {
            const config = this.data.inventory.find((it: any) => (it.type === item.type));
            if(config) {
                return config.multiple === true;
            }
        }
        return false;
    }

    inventoryItemStyle(item: any): any {
        if(this.data && this.data.inventory) {
            const config = this.data.inventory.find((it: any) => (it.type === item.type));
            if(config) {
                return {
                    backgroundImage: "url(" + config.path + ")"
                };
            }
        }
        return null;
    }

    inventoryItemClass(item: any): string {
        return "inventory-item" + ((this.playerState.inventory.activeItem === item.type)? " selected":"");
    }

    inventoryItemClicked(item: any) {
        console.log("inventory item clicked: ", item);
        this.socket.emit(SocketEvent.action, {action: "inventory", type: item.type});
        // console.log("playerState: ", this.playerState);
    }

    itemClicked(item: any) {
        const itemConfig = this.getItemType(item.type);
        if(itemConfig && itemConfig.action) {
            console.log("item clicked => action: ", itemConfig.action, item);
            const action = {
                ...item,
                action: itemConfig.action,
                x: undefined,
                y: undefined
            };
            this.socket.emit(SocketEvent.action, action);
        }
    }

    touchEvent(event: any) {
        this.socket.emit(SocketEvent.touch_event, event);
    }

    getItemType(type: string): any {
        if(this.data && this.data.itemTypes && this.data.itemTypes[type]) {
            return this.data.itemTypes[type];
        }
        else {
            return null;
        }
    }

    // hooks
    mounted() {
        console.log("PHASE DATA: ", this.data);
    }
}
