
import {Component, Vue} from 'vue-property-decorator';

@Component({
    name: "QrCode",
})
export default class QrCode extends Vue {
    // props

    // data

    // computed

    // methods

    // watch

    // hooks
}
