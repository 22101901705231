
export enum SocketEvent {
    player_id = "player_id",
    player_configuration = "player_configuration",
    game_state = "game_state",
    player_state = "player_state",
    action = "action",
    touch_event = "touch_event",
    synchro = "synchro"
}

export enum GameStatus {
    none = "none",
    offgame = "offgame",
    waiting = "waiting",
    running = "running",
    terminated = "terminated"
}

export enum PlayerStatus {
    configuring = "configuring",
    waiting = "waiting",
    entering = "entering",
    playing = "playing",
    unknown = "unknown",
    leaving = "leaving",
    terminated = "terminated"
}
