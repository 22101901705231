import GamePhaseMapPlacement from "@/components/phases/GamePhaseMapPlacement.vue";
import GameShaker, {AccelerometerMode} from "@/components/phases/GameShaker.vue";
import RouenStaticPhase from "@/components/phases/RouenStaticPhase.vue";
import GamePhaseJoypad from "@/components/phases/GamePhaseJoypad.vue";

export default [
    {
        name: "start",
        component: RouenStaticPhase,
        titleFr: "La prochaine interaction démarre bientôt",
        titleEn: "The next interaction begin soon",
        textStartFr: "Quand ce symbole apparaîtra sur la façade",
        textEndFr: "C'est que votre téléphone vous permettra d'intéragir en live !",
        textStartEn: "When this symbol appears on the facade",
        textEndEn: "You're phone let you interact with the show!",
        icon: "/images/icon-interaction.svg"
    },
    {
        name: "shake-game",
        component: GameShaker,

        frequency: 100,
        movementFrequency: 1000,
        movementThreshold: 10,
        accelerometerMode: AccelerometerMode.movementOnly,  // peut être : movementOnly, accelerationOnly, accelerationAndMovement
    },
    {
        name: "joystick",
        component: GamePhaseJoypad,

        maps: {
            "default": "/images/joystick-playground.png",
        },
    },
    {
        name: "intermission",
        component: RouenStaticPhase,
        titleFr: "Profitez du spectacle !",
        titleEn: "Enjoy the show!",
        textStartFr: "Durant le spectacle, lorsque vous verrez ce signe :",
        textEndFr: "C'est que votre téléphone vous permettra d'intéragir en live !",
        textStartEn: "During the show, when you see this sign:",
        textEndEn: "You're phone let you interact with the show!",
        icon: "/images/icon-interaction.svg"
    },
    {
        name: "interactive-map",
        component: GamePhaseMapPlacement,
        map: "default",
        maps: {
            "default": "/images/MIRE_CONSERVATOIRE_SmartPhone.jpg",
            "map1": "/images/MIRE_CONSERVATOIRE_SmartPhone.jpg"
        },
        itemTypes: {
            "default": "other",
            "other": {
                path: "/images/other.png",
                size: 40,
                extraSize: 40,
                minSize: 20,
                maxSize: 60
            },
            "mine": {
                path: "/images/mine.png",
                action: "item_activation",
                size: 50,
                extraSize: 60,
                minSize: 20,
                maxSize: 60
            }
        },
         inventory: [
             {
                 type: "type1",
                 multiple: false,
                 allwaysDisplayed: false,
                 path: "/images/mine.png"
        //     },
        //     {
        //         type: "type2",
        //         path: "/images/inventory2.svg"
        //     },
		//
        //     {
        //         type: "type3",
        //         multiple: true,
        //         allwaysDisplayed: true,
        //         path: "/images/inventory3.svg"
             }
         ],
        actionOn: "tap",
        originalScale: 0.5,
        originalOffset: {x: -0.5, y: -0.5},
        movable: false,
        zoomable: true,
        touchEvents: true,
        throttleFrequency: 250
    },
    {
        name: "end",
        component: RouenStaticPhase,
        titleFr: "Merci pour votre participation !",
        titleEnd: "Thank you for your participation",
    },
];
//
// nextFlash: {
//     start: timestamp,
//     attack: 100,
//     sustain: 0,
//     release: 300
// }
