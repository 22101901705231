

import {Component, Vue} from 'vue-property-decorator';
import {io, Socket} from "socket.io-client";
import {GameStatus, PlayerStatus, SocketEvent} from "@/enum";
import Action from "@/components/Action.vue";
import {Getter} from "vuex-class";
import phases from "@/phases";

enum PlayStep {
    connecting = "connecting",
    initializing = "initializing",
    offgame = "offgame",
    waiting = "waiting",
    playing = "playing"
}

@Component({
    name: "Play",
    components: {Action},
})
export default class Play extends Vue {
    // props

    @Getter("isConfigured") isConfigured!: boolean;

    // data
    step = PlayStep.connecting;
    socket: Socket|null = null;
    gameStatus: GameStatus = GameStatus.none;
    playerStatus: PlayerStatus = PlayerStatus.configuring;
    playerState: any = null;
    phases: Array<any> = phases;
    currentPhaseName: string|null = null;
    currentPhaseParameters: any = null;

    // computed
    get waitingPosition(): string {
        if(this.playerState && this.playerState.waitingPosition >= 0) {
            if(this.playerState.waitingPosition === 0) {
                return "<span class='big'>!</span>";
            }
            else {
                return this.playerState.waitingPosition + "<sup>e</sup>";
            }
        }
        else {
            return "?";
        }
    }

    get currentPhase(): any {
        const phaseName = this.currentPhaseName? this.currentPhaseName:"default";
        for(const phase of this.phases) {
            if(phase.name === phaseName) {
                return phase;
            }
        }

        return null;
    }

    get currentPhaseComputedParameters(): any {
        if(this.currentPhaseParameters) {
            return {
                ...this.currentPhase,
                ...this.currentPhaseParameters
            };
        }
        else {
            return this.currentPhase;
        }
    }

    // methods
    async connect() {
        try {
            this.socket = io(process.env.VUE_APP_BACK_CLIENT_SOCKET_URL);
            this.socket!.on("connect", () => {
                console.log("CONNECTED: ", this.socket!.id);
                this.step = PlayStep.initializing;
                // console.log("SEND PLAYER_ID: ", this.$store.state.currentSessionId);
                this.socket!.emit(SocketEvent.player_id, this.$store.state.currentSessionId);
            });

            // TODO: essayer de récupérer le timeout avec le on("connection_error") pour se mettre en offgame.

            this.socket!.on(SocketEvent.player_id, (state: any) => {
                console.log("PLAYER ID: ", state);
                this.$store.commit("setCurrentSessionId", state);
                this.step = PlayStep.offgame;
                this.socket!.emit(SocketEvent.player_configuration, this.$store.state.configuration);
            });

            this.socket!.on("disconnect", () => {
                console.log("DISCONNECTED: ", this.socket!.id);
                // this.$store.commit("setCurrentSessionId", this.socket!.id);
                this.step = PlayStep.connecting;
            });

            this.socket!.on(SocketEvent.game_state, (state: any) => {
                // console.log("GAME STATE: ", state);
                this.gameStatus = state.status;
                this.$store.commit("setGameState", state);
                this.checkStatus();
                this.checkPhase(state);
            });

            this.socket!.on(SocketEvent.player_state, (data: any) => {
                // console.log("PLAYER STATE: ", data);
                this.playerStatus = data.status;
                this.playerState = data.state;
                this.$store.commit("setPlayerState", data.state);
                this.checkStatus();
            });

            this.socket!.on(SocketEvent.synchro, (data: any) => {
                // console.log("Synchro: ", data.timestamp);
                this.$store.commit("putSynchro", {server: data.timestamp, client: Date.now()});
            });
        }
        catch(err) {
            console.log("can't connect...");
        }
    }

    checkStatus() {
        switch(this.gameStatus) {
            case GameStatus.none:
            case GameStatus.offgame:
                this.step = PlayStep.offgame;
                break;
            case GameStatus.waiting:
                this.step = PlayStep.waiting;
                break;
            case GameStatus.running:
                if(this.playerStatus === PlayerStatus.playing) {
                    this.step = PlayStep.playing;
                }
                else if(this.playerStatus === PlayerStatus.terminated) {
                    this.goOutside({name: "end", params: {termination: "loose"}});
                    return false;
                }
                else {
                    // this.step = PlayStep.waiting;
                    this.step = PlayStep.playing;
                }
                break;
            case GameStatus.terminated:
                this.goOutside({name: "end", params: {termination: "end"}});
                return false;
            default:
                this.step = PlayStep.playing;
        }
    }

    checkPhase(game: any) {
        if(game.clientPhase) {
            this.currentPhaseName = game.clientPhase.name;
            this.currentPhaseParameters = game.clientPhase.parameters;
        }
    }

    goOutside(route: any) {
        if(this.socket) {
            this.socket.close();
            this.socket = null;
        }
        this.$store.commit("setCurrentSessionId", null);
        this.$router.push(route);
    }

    quit() {
        this.goOutside({name: "home"});
    }

    // watch

    // hooks
    mounted() {
        if(this.isConfigured) {
            this.connect();
        }
        else {
            this.$router.push({name: "home"});
        }
    }

    beforeDestroy() {
        if(this.socket) {
            this.socket.close();
            this.socket = null;
        }
    }
}
