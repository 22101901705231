
import {Component, Vue, Prop} from 'vue-property-decorator';
import Action from "@/components/Action.vue";
import {paragraphize} from "@/helpers/helpers";
import QrCode from "@/components/QrCode.vue";

@Component({
    name: "RouenStaticPhase",
    components: {QrCode, Action},
})
export default class RouenStaticPhase extends Vue {
    // props
    @Prop() data: any;
    // @Prop(Object) socket: Socket|null = null;

    // data

    // computed
    get titleFr(): string|null {
        return this.data.titleFr? paragraphize(this.data.titleFr):null;
    }
    get titleEn(): string|null {
        return this.data.titleEn? paragraphize(this.data.titleEn):null;
    }

    get subtitle(): string|null {
        return this.data.subtitle? paragraphize(this.data.subtitle):null;
    }

    get text(): string|null {
        return this.data.text? paragraphize(this.data.text):null;
    }

    get leftTop(): string|null {
        return this.data.leftTop? paragraphize(this.data.leftTop):null;
    }

    get leftBottom(): string|null {
        return this.data.leftBottom? paragraphize(this.data.leftBottom):null;
    }

    get rightTop(): string|null {
        return this.data.rightTop? paragraphize(this.data.rightTop):null;
    }

    get rightBottom(): string|null {
        return this.data.rightBottom? paragraphize(this.data.rightBottom):null;
    }

    get image(): string|null {
        return this.data.image? this.data.image:null;
    }

    get imageBackground(): any {
        return this.data.background? {
            backgroundImage: "url('" + this.data.background + "')"
        }:null;
    }

    // methods
    // watch

    // hooks
    mounted() {
        console.log("DATA: ", this.data);
    }
}
