<script>
    import throttle from 'lodash.throttle';

    export default {
        name: 'Joypad',
        data: function () {
            return {
                open: true,
                show: false,
                touchZoneBox: 0,
                padpos: { "left": 0, "top": 0 },
                touchZoneRect: "null",
                joystickradius: "null",
                padradius: "null",
                joystickpos: { "left": 0, "top": 0 },
                XjoystickNorm: 0,
                YjoystickNorm:0,
                XYJoystickJson: { "uxmove": 0, "uymove": 0 },
                TouchIdentifier: "null",
            };
        },

        props: {
            BorderWidth: String,
            PadBorderWidth: String,
            BorderColor: String,
            PadBGColor: String,
            BGColor: String,
            TouchedColor: String
        },

        computed: {
            joystickstyle() {
                const maxMove = this.padradius - this.joystickradius;

                let alpha, h, x, y;

                if(this.XYJoystickJson.uymove === 0) {
                    y = 0;

                    if(this.XYJoystickJson.uxmove === 0) {
                        x = 0;
                    }
                    else {
                        x = this.XYJoystickJson.uxmove * maxMove;
                    }
                }
                else {
                    if(this.XYJoystickJson.uxmove === 0) {
                        x = 0;
                        y = this.XYJoystickJson.uymove * maxMove;
                    }
                    else {
                        alpha = Math.atan(this.XYJoystickJson.uymove / this.XYJoystickJson.uxmove);
                        if(this.XYJoystickJson.uxmove < 0) {
                            if(this.XYJoystickJson.uymove < 0) {
                                alpha = -Math.PI + alpha
                            }
                            else {
                                alpha = Math.PI + alpha
                            }
                        }
                        h = Math.min(maxMove, this.XYJoystickJson.uxmove / Math.cos(alpha) * maxMove);

                        x = Math.cos(alpha) * h;
                        y = Math.sin(alpha) * h;
                    }
                }

                // console.log(alpha/Math.PI*180, h, x, y);

                return {
                    'border-width': this.BorderWidth + 'px',
                    'border-color': this.BorderColor,
                    'background-color': this.BGColor,
                    transform: "translate(" + (this.padradius - this.joystickradius + x) + "px, " + (this.padradius - this.joystickradius - y) + "px)"
                };
            },
            padstyle() {
                return 'border-width: ' + this.PadBorderWidth + 'px;' + 'border-color: ' + this.BorderColor + ';' + 'background-color: ' + this.PadBGColor;
            },
            joystickBGstyle() {
                return 'background-color: ' + this.TouchedColor;
            },
        },

        mounted() {
            this.InitMethod()
            window.addEventListener(
                "resize",
                this.handleOrientationChange
            );
        },

        watch: {
            touchZoneBox: function () {
                this.touchZoneRect = this.$refs.touchZone.getBoundingClientRect()
                this.padradius = this.$refs.padRef.getBoundingClientRect().width / 2
                this.joystickradius = this.$refs.joystickBorderRef.getBoundingClientRect().width / 2;
                this.padpos.left = this.$refs.padRef.getBoundingClientRect().left + this.padradius;
                this.padpos.top = this.$refs.padRef.getBoundingClientRect().top + this.padradius;
                this.joystickpos.left = this.$refs.joystickBorderRef.getBoundingClientRect().left;
                this.joystickpos.top = this.$refs.joystickBorderRef.getBoundingClientRect().top;

                // this.$refs.joystickBorderRef.style.setProperty("transition", "transform 0s");
                // this.$refs.joystickBorderRef.style.transform = 'translateX(' + (this.touchZoneRect.width / 2 - this.joystickradius) + 'px)';
                // this.$refs.joystickBorderRef.style.transform += 'translateY(' + (this.touchZoneRect.height / 2 - this.joystickradius) + 'px)';

            }
        },

        methods: {
            throttleEmit: throttle(function () {
                this.$emit("move", this.XYJoystickJson);
            }, 250, { 'trailing': false }),

            MovePad(x, y, padref, duration) {

                var XpadRef = this.padpos.left - x;
                var YpadRef = this.padpos.top - y;
                var disttouchcenter = Math.sqrt(Math.pow(XpadRef, 2) + Math.pow(YpadRef, 2));

                this.joystickpos.left = x - this.joystickradius;
                this.joystickpos.top = y - this.joystickradius;


                if (disttouchcenter < this.padradius) {

                    // padref.style.setProperty("transition", 'transform ' + duration + 's');
                    // padref.style.transform = 'translateX(' + (this.joystickpos.left - this.touchZoneRect.left) + 'px)';
                    // padref.style.transform += 'translateY(' + (this.joystickpos.top - this.touchZoneRect.top) + 'px)';

                    this.XjoystickNorm = -XpadRef / this.padradius;
                    this.YjoystickNorm = YpadRef / this.padradius;

                    this.XYJoystickJson = {};
                    this.XYJoystickJson.uxmove = this.XjoystickNorm;
                    this.XYJoystickJson.uymove = this.YjoystickNorm;

                } else {

                    var alpha = Math.atan((YpadRef) / (XpadRef));
                    var cosalpha = Math.cos(alpha);
                    var sinalpha = Math.sin(alpha)

                    if (-XpadRef / this.padradius < 1 && -XpadRef / this.padradius > -1) {
                        this.XjoystickNorm = -XpadRef / this.padradius;
                    } else {
                        if (-XpadRef / this.padradius > 1) {
                            this.XjoystickNorm = 1
                        } else {
                            this.XjoystickNorm = -1
                        }
                    }
                    if (YpadRef / this.padradius < 1 && YpadRef / this.padradius > -1) {
                        this.YjoystickNorm = YpadRef / this.padradius;
                    } else {
                        if (YpadRef / this.padradius > 1) {
                            this.YjoystickNorm = 1
                        } else {
                            this.YjoystickNorm = -1
                        }
                    }

                    if (XpadRef > 0) {

                        this.joystickpos.left = -this.padradius * cosalpha + this.padpos.left - this.joystickradius;
                        this.joystickpos.top = -this.padradius * sinalpha + this.padpos.top - this.joystickradius;

                        // padref.style.setProperty("transition", 'transform ' + duration + 's');
                        // padref.style.transform = 'translateX(' + (this.joystickpos.left - this.touchZoneRect.left) + 'px)';
                        // padref.style.transform += 'translateY(' + (this.joystickpos.top - this.touchZoneRect.top) + 'px)';

                        //this.XjoystickNorm = -cosalpha;
                        //this.YjoystickNorm = sinalpha;

                    } else {

                        this.joystickpos.left = this.padradius * Math.cos(alpha) + this.padpos.left - this.joystickradius;
                        this.joystickpos.top = this.padradius * Math.sin(alpha) + this.padpos.top - this.joystickradius;

                        // padref.style.setProperty("transition", 'transform ' + duration + 's');
                        // padref.style.transform = 'translateX(' + (this.joystickpos.left - this.touchZoneRect.left) + 'px)';
                        // padref.style.transform += 'translateY(' + (this.joystickpos.top - this.touchZoneRect.top) + 'px)';

                        //this.XjoystickNorm = cosalpha;
                        //this.YjoystickNorm = -sinalpha;
                    }

                    this.XYJoystickJson = {};
                    this.XYJoystickJson.uxmove = this.XjoystickNorm;
                    this.XYJoystickJson.uymove = this.YjoystickNorm;

                }

                return (this.XYJoystickJson)
            },

            InitMethod() {
                this.touchZoneRect = this.$refs.touchZone.getBoundingClientRect()
                this.padradius = this.$refs.padRef.getBoundingClientRect().width / 2
                this.joystickradius = this.$refs.joystickBorderRef.getBoundingClientRect().width / 2;
                this.padpos.left = this.$refs.padRef.getBoundingClientRect().left + this.padradius;
                this.padpos.top = this.$refs.padRef.getBoundingClientRect().top + this.padradius;
                this.joystickpos.left = this.$refs.joystickBorderRef.getBoundingClientRect().left;
                this.joystickpos.top = this.$refs.joystickBorderRef.getBoundingClientRect().top;

                // this.$refs.joystickBorderRef.style.setProperty("transition", "transform 0s");
                // this.$refs.joystickBorderRef.style.transform = 'translateX(' + (this.touchZoneRect.width / 2 - this.joystickradius) + 'px)';
                // this.$refs.joystickBorderRef.style.transform += 'translateY(' + (this.touchZoneRect.height / 2 - this.joystickradius) + 'px)';
            },


            handleOrientationChange() {
                this.touchZoneBox = this.$refs.touchZone.getBoundingClientRect()
            },


            testmethod() {
                console.log('test')
            },

            touchStart(event) {
                var touches = event.changedTouches
                this.TouchIdentifier = touches[0].identifier
                this.XYJoystickJson = this.MovePad(touches[0].clientX, touches[0].clientY, this.$refs.joystickBorderRef, 0);
                this.show = true
                this.$emit("move", this.XYJoystickJson);
            },

            touchMove(event) {
                var touches = event.changedTouches

                var toucheFinaleID = this.TouchIndexById(touches, this.TouchIdentifier)
                this.XYJoystickJson = this.MovePad(touches[toucheFinaleID].clientX, touches[toucheFinaleID].clientY, this.$refs.joystickBorderRef, 0)
                this.throttleEmit();
            },

            TouchIndexById(touches, idToFind) {
                for (var i = 0; i < touches.length; i++) {
                    var id = touches[i].identifier;

                    if (id == idToFind) {
                        return i;
                    }
                }
                return -1;    // toucher non trouvé
            },

            touchEnd() {
                // this.$refs.joystickBorderRef.style.setProperty("transition", "transform 0.3s");
                // this.$refs.joystickBorderRef.style.transform = 'translateX(' + (this.touchZoneRect.width / 2 - this.joystickradius) + 'px)';
                // this.$refs.joystickBorderRef.style.transform += 'translateY(' + (this.touchZoneRect.height / 2 - this.joystickradius) + 'px)';

                this.XjoystickNorm = 0;
                this.YjoystickNorm = 0;

                this.XYJoystickJson = {};
                this.XYJoystickJson.uxmove = this.XjoystickNorm;
                this.XYJoystickJson.uymove = this.YjoystickNorm;
                this.show = false

                this.$emit("move", this.XYJoystickJson);
            },

            toggle() {
                this.$refs['fullscreen'].toggle() // recommended
                // this.fullscreen = !this.fullscreen // deprecated
            },
            fullscreenChange(fullscreen) {
                this.fullscreen = fullscreen
            }
        }
    }


</script>

<template>
    <div v-body-scroll-lock="open" class="joypad">
        <div class="square">
            <div class="rounded-circle pad padBorder" :style="padstyle" ref="padRef" id="padID"></div>
            <div class="rounded-circle joystick joystickBorder" :style="joystickstyle" ref="joystickBorderRef"><transition name="fade"><div v-show="show" class="rounded-circle joystick joystickBG" :style="joystickBGstyle"></div></transition></div>
            <div class="fullsize" v-on:touchmove="touchMove" v-on:touchstart="touchStart" v-on:touchend="touchEnd" ref="touchZone"></div>
        </div>
    </div>
</template>

<style lang="scss">
    .joypad {

        .square {
            position: relative;
            width: 84px;
            height: 84px;
        }

        .rounded-circle {
            border-radius: 1000px;
        }

        .fade-enter-active {
            transition: opacity 0.1s ease;
        }

        .fade-leave-active {
            transition: opacity 0.4s ease;
        }

        .fade-enter, .fade-leave-to {
            opacity: 0;
        }

        .fullsize {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0%;
            left: 0%;
            white-space: pre-line;
            font-size: 8px;
        }

        .pad {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            box-sizing: border-box;
        }

        .padBorder {
            border-width: 3px;
            border-color: white;
            border-style: solid;
            // box-sizing: border-box;
        }

        .joystick {
            width: 60%;
            height: 60%;
            position: absolute;
            -moz-transition: transform 0s;
            -webkit-transition: transform 0s;
            -ms-transition: transform 0s;
            -o-transition: transform 0s;
            transition: transform 0s;
        }

        .joystickBG {
            width: 100%;
            height: 100%;
            position: relative;
            background-color: rgba(white, 0.6);
            box-sizing: border-box;
        }

        .joystickBorder {
            border-width: 3px;
            border-color: white;
            border-style: solid;
        }

    }
</style>
